import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Head from "../components/head"
const IndexPage = () => (
  <Layout>
    <Head
      title="企業理念"
      description="株式会社アイテクスの企業理念をご案内致します。"
    />
    <section className="hero is-halfheight">
      <div className="hero-body">
        <div className="container">
          <h1 className="title">企業理念</h1>
          <h2 className="subtitle">Philosophy</h2>
        </div>
      </div>
    </section>
    <section className="section is-medium has-background-white-bis">
      <div className="container">
        <StaticImage
          src="../../content/assets/16x9-segodon.jpg"
          alt="企業理念 西郷隆盛"
          placeholder="blurred"
          layout="fullWidth"
        />
        <h1 className="title mt-6">
          毎日を楽しく幸せに、
          <br />
          元気で楽しい社会をつくる
        </h1>
        <h2 className="subtitle">Philosophy</h2>
        <div className="content">
          <p>人の想いにこだわる。</p>
          <p>
            ITの機器やソフトウェアではなく「<strong>利用する人</strong>
            」を中心に考えるモノづくりとサービスを提供します。
          </p>
          <p>
            お客様はもちろん、パートナー企業や従業員まで大切に気配りを忘れず、すべての活動に「
            <strong>楽しさ</strong>」を追求する。
            <br />
            常にアップデートし続けていく、それが私たちアイテクスです。
          </p>
        </div>
      </div>
    </section>
    <section className="section is-medium">
      <div className="container">
        <div className="columns is-variable is-5">
          <div className="column">
            <h1 className="title">楽しさを追求すること</h1>
            <h2 className="subtitle">Mission</h2>
            <div className="content">
              <p>人の人生を楽しく豊かにする、それが私たちのクレドです。</p>
              <p>
                まずは自分たちが楽しむ。
                <br />
                周囲からも楽しい人と言われる。
                <br />
                そして誰かの人生を豊かにします。
              </p>
            </div>
          </div>
          <div className="column"></div>
        </div>
      </div>
    </section>
    <section className="section is-medium has-background-white-bis">
      <div className="container has-text-centered">
        <h1 className="title">私たちが考える「価値観」</h1>
        <h2 className="subtitle">Core Value</h2>
        <div className="columns is-variable is-5">
          <div className="column">
            <div className="block">
              <StaticImage
                src="../../content/assets/philosophy-improve-work-efficiency.png"
                alt="Improve Work Efficiency"
                placeholder="blurred"
                layout="fullWidth"
              />
            </div>
            <div className="content">
              <h3>Improve Work Efficiency</h3>
              <p>
                ITのエキスパートとして
                <br />
                「楽する仕事」ができる環境を提供すること
              </p>
            </div>
          </div>
          <div className="column">
            <div className="block">
              <StaticImage
                src="../../content/assets/philosophy-make-people-happy.png"
                alt="Make People Happy"
                placeholder="blurred"
                layout="fullWidth"
              />
            </div>
            <div className="content">
              <h3>Make People Happy</h3>
              <p>ITを通じて一人でも多くの人に喜んでいただくこと</p>
            </div>
          </div>
          <div className="column">
            <div className="block">
              <StaticImage
                src="../../content/assets/philosophy-dreams-come-true.png"
                alt="Dreams Come True"
                placeholder="blurred"
                layout="fullWidth"
              />
            </div>
            <div className="content">
              <h3>Dreams Come True</h3>
              <p>お客様の満足を通じて夢や幸せを実現すること</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section is-medium">
      <div className="container">
        <StaticImage
          src="../../content/assets/16x9-philosophy-message.jpg"
          alt="代表メッセージ"
          placeholder="blurred"
          layout="fullWidth"
        />
        <div className="has-text-centered-tablet mt-6">
          <div className="content has-max-width-600">
            <p className="is-size-5-tablet">
              <strong>
                人生の大半を仕事と寝る時間で過ごすのだから
                <br />
                アイテクスは、自分たちが仕事で楽しむことを大切にし、
                <br />
                お客様と共に幸せに成長出来る企業を目指します！
              </strong>
            </p>
            <p>
              私たちは、鹿児島でシステム開発を中心に
              <br />
              Webサイト制作、ITに関するコンサルティング・運用支援・保守サービス等を行っている会社です。
            </p>
            <p>
              ITのエキスパートとして「楽する仕事」が出来る環境を提供し、一人でも多くのお客様に喜んでいただけること、また、お客様の満足を通じて私たちの「夢や幸せ」を実現してくことが、ITxSのミッションです。
            </p>
            <p className="has-text-right-mobile">
              株式会社アイテクス
              <br />
              代表取締役社長　永山 邦哉
            </p>
          </div>
        </div>
        <div className="columns is-variable is-5 mt-6">
          <div className="column is-one-quarter is-offset-one-fifth">
            <StaticImage
              src="../../content/assets/logo-itxs.png"
              alt="株式会社アイテクスのロゴ"
              placeholder="blurred"
              layout="fullWidth"
            />
          </div>
          <div className="column">
            <h2 className="title">社名の由来</h2>
            <h3 className="subtitle">Origin</h3>
            <div className="content">
              <p>
                <strong>
                  アイテクスとは <span className="has-text-info">“IT”</span>
                  ×（かける）
                  <span className="has-text-info">“S”</span> の組合せ
                </strong>
              </p>
              <p>
                ITを使う全ての人たちに
                <br />
                <span className="has-text-info">Substantial</span>（十分な）
                <span className="has-text-info">Support</span>（支え）や、
                <span className="has-text-info">Security</span>
                （安心・安全）を提供し
                <br />
                ITを使う全ての人たちが
                <br />
                <span className="has-text-info">Smile</span>（笑顔）で
                <span className="has-text-info">Success</span>
                （成功）する手助けをします。
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
export default IndexPage
